import copy from '@/assets/icon/copy-icon.svg';
import shield from '@/assets/icon/shield.svg';
import pdf from '@/assets/icon/pdf-file.svg';
import notifyCheck from '@/assets/notify-icon/check.svg';
import portfolio from '@/assets/icon/portfolio.svg';
import watchList from '@/assets/icon/search-list.svg';
import paperclip from '@/assets/icon/paperclip.svg';
import check from '@/assets/icon/check.svg';
import cross from '@/assets/icon/close.svg';
import bar from '@/assets/bar.svg';
import solidCandle from '@/assets/solid-candle.svg';
import strokeCandle from '@/assets/stroke-candle.svg';
import indicator from '@/assets/indicator.svg';
import area from '@/assets/area.svg';
import left from '@/assets/icon/arrow-left.svg';
import trash from '@/assets/icon/trash.svg';
import up from '@/assets/icon/move-up.svg';
import iconLogout from '@/assets/icon/log-out.svg';
import search from '@/assets/icon/search.svg';
import plus from '@/assets/icon/plus.svg';
import plug from '@/assets/plug.svg';
import searchIcon from '@/assets/icon/search.svg';
import addWatch from '@/assets/icon/addWatch.svg';
import arrowUp from '@/assets/icon/move-up.svg';
import threeDots from '@/assets/icon/three-dots.svg';
import keyboard from '@/assets/icon/keyboard.svg';
import options from '@/assets/icon/options.svg';
import refresh from '@/assets/icon/refresh.svg';
import eye from '@/assets/icon/eye.svg';
import closeeye from '@/assets/icon/closeeye.svg';
import user from '@/assets/icon/user-icon.svg';
import fullHeight from '@/assets/icon/icon-portfolio-height/full-height.svg';
import defaultHeight from '@/assets/icon/icon-portfolio-height/default-height.svg';
import collapse from '@/assets/icon/icon-portfolio-height/none-height.svg';
import height from '@/assets/icon/icon-portfolio-height/height-up.svg';
import star from '@/assets/icon/star.svg';
import starFill from '@/assets/icon/starFill.svg';
import output from '@/assets/icon/output.svg';
import setting from '@/assets/icon/setting.svg';
import zoom from '@/assets/icon/zoom.svg';
import loading from '@/assets/icon/loading.svg';

const icons = {
  // table height icon
  zoom: zoom,
  loading: loading,
  closeeye: closeeye,
  setting: setting,
  collapse: collapse,
  height: height,
  fullHeight: fullHeight,
  defaultHeight: defaultHeight,
  // end table height icon
  user: user,
  eye: eye,
  keyboard: keyboard,
  threeDots: threeDots,
  arrowUp: arrowUp,
  addWatch: addWatch,
  searchIcon: searchIcon,
  plug: plug,
  plus: plus,
  iconLogout: iconLogout,
  search: search,
  up: up,
  trash: trash,
  left: left,
  check: check,
  cross: cross,
  solidCandle: solidCandle,
  bar: bar,
  strokeCandle: strokeCandle,
  indicator: indicator,
  area: area,
  copy: copy,
  shield: shield,
  pdf: pdf,
  notifyCheck: notifyCheck,
  paperclip: paperclip,
  watchList: watchList,
  portfolio: portfolio,
  options: options,
  refresh: refresh,
  star: star,
  starFill: starFill,
  output: output,
};

export default icons;
