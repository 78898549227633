exports.setting = function (type) {
  const setting = {
    locale: 'ru-RU',
    thousandsSeparator: ' ',
    customApi: {
      formatDate: (dateTimeFormat, timestamp) => {
        return new Intl.DateTimeFormat('ru', {
          year: '2-digit',
          month: 'numeric',
          day: 'numeric',
          hour: '2-digit',
          minute: '2-digit',
        }).format(timestamp);
      },
      // formatBigNumber?: (value: string | number) => string
    },
    styles: {
      grid: {
        show: true,
        horizontal: {
          show: true,
          size: 1,
          color: 'rgba(255, 255, 255, 0.25)',
          style: 'dashed',
          dashedValue: [1, 4],
        },
        vertical: {
          show: true,
          size: 1,
          color: 'rgba(255, 255, 255, 0.25)',
          style: 'dashed',
          dashedValue: [1, 4],
        },
      },
      candle: {
        type: 'candle_solid',
        bar: {
          upColor: '#2DC08E',
          downColor: '#F92855',
          noChangeColor: '#888888',
          upBorderColor: '#2DC08E',
          downBorderColor: '#F92855',
          noChangeBorderColor: '#888888',
          upWickColor: '#2DC08E',
          downWickColor: '#F92855',
          noChangeWickColor: '#888888',
        },
        area: {
          lineSize: 2,
          lineColor: '#2196F3',
          value: 'close',
          backgroundColor: [
            {
              offset: 0,
              color: 'rgba(33, 150, 243, 0.01)',
            },
            {
              offset: 1,
              color: 'rgba(33, 150, 243, 0.2)',
            },
          ],
        },
        priceMark: {
          show: true,
          high: {
            show: true,
            color: '#D9D9D9',
            textMargin: 5,
            textSize: 10,
            textFamily: 'Helvetica Neue',
            textWeight: 'normal',
          },
          low: {
            show: true,
            color: '#D9D9D9',
            textMargin: 5,
            textSize: 10,
            textFamily: 'Helvetica Neue',
            textWeight: 'normal',
          },
          last: {
            show: true,
            upColor: '#2DC08E',
            downColor: '#F92855',
            noChangeColor: '#888888',
            line: {
              show: true,
              style: 'dashed',
              dashedValue: [4, 4],
              size: 1,
            },
            text: {
              show: true,
              style: 'fill',
              size: 12,
              paddingLeft: 4,
              paddingTop: 4,
              paddingRight: 4,
              paddingBottom: 4,
              borderStyle: 'solid',
              borderSize: 0,
              borderColor: 'transparent',
              borderDashedValue: [2, 2],
              color: '#FFFFFF',
              family: 'Helvetica Neue',
              weight: 'normal',
              borderRadius: 2,
            },
          },
        },
        tooltip: {
          showRule: 'always',
          showType: 'standard',
          custom: null,
          defaultValue: 'n/a',
          rect: {
            position: 'fixed',
            paddingLeft: 0,
            paddingRight: 0,
            paddingTop: 0,
            paddingBottom: 6,
            offsetLeft: 10,
            offsetTop: 8,
            offsetRight: 10,
            offsetBottom: 8,
            borderRadius: 4,
            borderSize: 1,
            borderColor: '#f2f3f5',
            color: '#FEFEFE',
          },
          text: {
            // текст со временем и объёмами вверху графика
            size: 14,
            family: 'Helvetica Neue',
            weight: 'normal',
            color: '#D9D9D9',
            marginLeft: 6,
            marginTop: 0,
            marginRight: 0,
            marginBottom: 6,
          },
          icons: [],
        },
      },
      indicator: {
        ohlc: {
          upColor: 'rgba(45, 192, 142, .7)',
          downColor: 'rgba(249, 40, 85, .7)',
          noChangeColor: '#888888',
        },
        bars: [
          {
            style: 'fill',
            borderStyle: 'solid',
            borderSize: 1,
            borderDashedValue: [2, 2],
            upColor: 'rgba(45, 192, 142, .7)',
            downColor: 'rgba(249, 40, 85, .7)',
            noChangeColor: '#888888',
          },
        ],
        lines: [
          {
            style: 'solid',
            smooth: false,
            size: 1,
            dashedValue: [2, 2],
            color: '#FF9600',
          },
          {
            style: 'solid',
            smooth: false,
            size: 1,
            dashedValue: [2, 2],
            color: '#935EBD',
          },
          {
            style: 'solid',
            smooth: false,
            size: 1,
            dashedValue: [2, 2],
            color: '#2196F3',
          },
          {
            style: 'solid',
            smooth: false,
            size: 1,
            dashedValue: [2, 2],
            color: '#E11D74',
          },
          {
            style: 'solid',
            smooth: false,
            size: 1,
            dashedValue: [2, 2],
            color: '#01C5C4',
          },
        ],
        circles: [
          {
            style: 'fill',
            borderStyle: 'solid',
            borderSize: 1,
            borderDashedValue: [2, 2],
            upColor: 'rgba(45, 192, 142, .7)',
            downColor: 'rgba(249, 40, 85, .7)',
            noChangeColor: '#888888',
          },
        ],
        lastValueMark: {
          show: false,
          text: {
            show: false,
            style: 'fill',
            color: '#FFFFFF',
            size: 12,
            family: 'Helvetica Neue',
            weight: 'normal',
            borderStyle: 'solid',
            borderSize: 1,
            borderDashedValue: [2, 2],
            paddingLeft: 4,
            paddingTop: 4,
            paddingRight: 4,
            paddingBottom: 4,
            borderRadius: 2,
          },
        },
        tooltip: {
          showRule: 'always',
          showType: 'standard',
          showName: true,
          showParams: true,
          defaultValue: 'n/a',
          text: {
            size: 12,
            family: 'Helvetica Neue',
            weight: 'normal',
            color: '#D9D9D9',
            marginTop: 8,
            marginRight: 6,
            marginBottom: 10,
            marginLeft: 6,
          },
          icons: [],
        },
      },
      xAxis: {
        show: true,
        size: 'auto',
        axisLine: {
          show: true,
          color: '#888888',
          size: 1,
        },
        tickText: {
          // нижний текст под графиком
          show: true,
          color: 'rgba(143, 145, 163, 1)',
          family: 'Helvetica Neue',
          weight: 'normal',
          size: 12,
          marginStart: 4,
          marginEnd: 4,
        },
        tickLine: {
          show: true,
          size: 1,
          length: 3,
          color: '#888888',
        },
      },
      yAxis: {
        show: true,
        size: 'auto',
        position: 'right',
        type: 'normal',
        inside: true,
        reverse: false,
        axisLine: {
          show: true,
          color: 'rgba(143, 145, 163, 1)',
          size: 1,
        },
        tickText: {
          // текст справа от графИКА
          show: true,
          color: 'rgba(143, 145, 163, 1)',
          family: 'Helvetica Neue',
          weight: 'normal',
          size: 12,
          marginStart: 4,
          marginEnd: 4,
        },
        tickLine: {
          show: true,
          size: 1,
          length: 3,
          color: 'rgba(143, 145, 163, 1)',
        },
      },
      separator: {
        size: 1,
        color: '#888888',
        fill: true,
        activeBackgroundColor: 'rgba(230, 230, 230, .15)',
      },
      crosshair: {
        show: true,
        horizontal: {
          show: true,
          line: {
            show: true,
            style: 'dashed',
            dashedValue: [4, 2],
            size: 1,
            color: '#888888',
          },
          text: {
            show: true,
            style: 'fill',
            color: '#FFFFFF',
            size: 12,
            family: 'Helvetica Neue',
            weight: 'normal',
            borderStyle: 'solid',
            borderDashedValue: [2, 2],
            borderSize: 1,
            borderColor: '#686D76',
            borderRadius: 2,
            paddingLeft: 4,
            paddingRight: 4,
            paddingTop: 4,
            paddingBottom: 4,
            backgroundColor: '#686D76',
          },
        },
        vertical: {
          show: true,
          line: {
            show: true,
            style: 'dashed',
            dashedValue: [4, 2],
            size: 1,
            color: '#888888',
          },
          text: {
            show: true,
            style: 'fill',
            color: '#FFFFFF',
            size: 12,
            family: 'Helvetica Neue',
            weight: 'normal',
            borderStyle: 'solid',
            borderDashedValue: [2, 2],
            borderSize: 1,
            borderColor: '#686D76',
            borderRadius: 2,
            paddingLeft: 4,
            paddingRight: 4,
            paddingTop: 4,
            paddingBottom: 4,
            backgroundColor: '#686D76',
          },
        },
      },
      overlay: {
        point: {
          color: '#1677FF',
          borderColor: 'rgba(22, 119, 255, 0.35)',
          borderSize: 1,
          radius: 5,
          activeColor: '#1677FF',
          activeBorderColor: 'rgba(22, 119, 255, 0.35)',
          activeBorderSize: 3,
          activeRadius: 5,
        },
        line: {
          style: 'solid',
          smooth: false,
          color: '#1677FF',
          size: 1,
          dashedValue: [2, 2],
        },
        rect: {
          style: 'fill',
          color: 'rgba(22, 119, 255, 0.25)',
          borderColor: '#1677FF',
          borderSize: 1,
          borderRadius: 0,
          borderStyle: 'solid',
          borderDashedValue: [2, 2],
        },
        polygon: {
          style: 'fill',
          color: '#1677FF',
          borderColor: '#1677FF',
          borderSize: 1,
          borderStyle: 'solid',
          borderDashedValue: [2, 2],
        },
        circle: {
          style: 'fill',
          color: 'rgba(22, 119, 255, 0.25)',
          borderColor: '#1677FF',
          borderSize: 1,
          borderStyle: 'solid',
          borderDashedValue: [2, 2],
        },
        arc: {
          style: 'solid',
          color: '#1677FF',
          size: 1,
          dashedValue: [2, 2],
        },
        text: {
          style: 'fill',
          color: '#FFFFFF',
          size: 12,
          family: 'Helvetica Neue',
          weight: 'normal',
          borderStyle: 'solid',
          borderDashedValue: [2, 2],
          borderSize: 0,
          borderRadius: 2,
          borderColor: '#1677FF',
          paddingLeft: 0,
          paddingRight: 0,
          paddingTop: 0,
          paddingBottom: 0,
          backgroundColor: 'transparent',
        },
        rectText: {
          style: 'fill',
          color: '#FFFFFF',
          size: 12,
          family: 'Helvetica Neue',
          weight: 'normal',
          borderStyle: 'solid',
          borderDashedValue: [2, 2],
          borderSize: 1,
          borderRadius: 2,
          borderColor: '#1677FF',
          paddingLeft: 4,
          paddingRight: 4,
          paddingTop: 4,
          paddingBottom: 4,
          backgroundColor: '#1677FF',
        },
      },
    },
  };

  // for mobile chart
  setting.styles.candle.tooltip.showRule = type !== 'mobile' ? 'always' : 'none';
  setting.styles.yAxis.tickText.size = type !== 'mobile' ? 12 : 10;
  setting.styles.xAxis.tickText.size = type !== 'mobile' ? 12 : 10;

  return setting;
};
