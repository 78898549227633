<template>
  <div class="setting-wl">
    <div>
      <div class="ttile">Переименовать {{ data.key }} ?</div>
      <c-label text="Новое название" />
      <input v-model="newName" type="text" />
    </div>

    <button :disabled="!newName.trim()" class="primary center" @click.prevent="rename(data)">Применить</button>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex';
import CLabel from '@/components/UI/ComponentLabel.vue';

export default {
  name: 'watchlist-setting',
  components: { CLabel },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      newName: '',
    };
  },
  computed: {
    ...mapGetters({
      user: 'getUser',
    }),
  },
  methods: {
    ...mapMutations({
      changeWlName: 'changeWlName',
    }),
    ...mapActions({
      updateSettings: 'updateSettings',
    }),
    rename(data) {
      this.changeWlName({ key: data.val, value: this.newName });
      this.updateSettings({ setting: { main: { wls: this.user.wls } } });
    },
  },
  watch: {},
  created() {},
  mounted() {},
  beforeUnmount() {},
  unmounted() {},
};
</script>

<style lang="scss" scoped>
@import '@/style/helper/index';

.setting-wl {
  display: flex;
  flex-direction: column;

  .title {
    margin-bottom: 18px;
  }

  button {
    margin-top: 50px;
  }
}
</style>
