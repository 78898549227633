<template>
  <div class="main-template">
    <setting-line-component />
    <router-view />
    <!-- todo мобильная доделать -->
    <div v-if="isMobile">
      <div v-if="['demo'].includes(user.type) && $route.name !== 'order'" class="demo-info">
        <div class="info">
          Вы находитесь в демо режиме, <br />
          для полного доступа необходимо
        </div>
        <button class="primary center" @click="openAccount">Открыть счет</button>
      </div>
      <app-bar />
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions, mapMutations } from 'vuex';
import SettingLineComponent from '@/components/SettingLineComponent.vue';
import AppBar from '@/components/app-bar/AppBar.vue';

export default {
  name: 'main-template',
  components: { AppBar, SettingLineComponent },
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      isMobile: 'isMobile',
      user: 'getUser',
    }),
  },
  methods: {
    ...mapMutations({
      changeModals: 'changeModals',
    }),
    ...mapActions({}),
    openAccount() {
      this.changeModals({ boarding: { visible: true, data: {} } });
    },
  },
  watch: {},
  created() {},
  mounted() {},
  unmounted() {},
};
</script>

<style lang="scss">
@import '@/style/helper/index';

.main-template {
  min-height: 100vh;
  height: 100%;
  @include breakpoint-down(md) {
    min-height: calc(100vh - (var(--nav-main-height) + var(--setting-line-height) + 30px));
  }

  .demo-info {
    background-color: rgb(21 21 26 / 68%);
    text-align: center;
    padding: 10px 0;
    position: fixed;
    right: 0;
    left: 0;
    bottom: calc(env(safe-area-inset-bottom) + var(--nav-main-height));

    .info {
      font-size: 14px;
      margin: 0 auto 8px;
      width: 300px;
    }
  }
}
</style>
