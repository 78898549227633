<template>
  <!-- todo уделить больше внимания для стилей -->
  <div class="mass-instruments">
    <div class="header">
      <c-switch v-model="localMassInstrumentsOrder" />
      <c-label text="Список из HOT" />
    </div>
    <div v-if="massInstrumentsOrder" :style="{ height: height + 'px' }" class="instruments-wrap-table">
      <c-table :columnDefs="columnDefs" :rows="instruments" @updateRow="listUpdate" />
      <button class="primary center" style="margin-top: 20px" @click.prevent="sendMass">Отправить</button>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex';
import CSwitch from '@/components/UI/ComponentSwitch.vue';
import CTable from '@/components/UI/ComponentTable.vue';
import CLabel from '@/components/UI/ComponentLabel.vue';

export default {
  name: 'CabinetComponent',
  components: { CLabel, CTable, CSwitch },
  data: function () {
    return {
      localMassInstrumentsOrder: false,
      instruments: [],
      instrumentsOrder: [],
      height: null,
      columnDefs: [
        { name: '', field: 'selected', type: 'boolean', sort: true, flex: '0 0 35px' },
        { name: 'Тикер', field: 'symbol', type: 'string', sort: true, search: 'text', flex: 3 },
        {
          name: 'Количество',
          field: 'quantity',
          type: 'number',
          sort: true,
          editable: (row) => row.selected,
          flex: 1,
        },
        { name: 'Сумма', field: 'cost', type: 'currency', sort: true, flex: 2 },
      ],
    };
  },
  computed: {
    ...mapGetters({
      watchList: 'getWatchList',
      massInstrumentsOrder: 'getMassInstrumentsOrder',
      getOrder: 'getOrder',
      selectedSymbol: 'getSelectedSymbol',
    }),
  },
  methods: {
    ...mapMutations({
      changeMassInstrumentsOrder: 'changeMassInstrumentsOrder',
      // changeOrder: 'changeOrder',
      changeOrderList: 'changeOrderList',
    }),
    ...mapActions({
      sendOrder: 'sendOrder',
      updateSelectedSymbol: 'updateSelectedSymbol',
    }),
    async sendMass() {
      for (const instrument of this.instruments.filter((each) => each.selected)) {
        await this.updateSelectedSymbol(instrument.symbol);
        console.log(JSON.stringify(this.getOrder));
        for (const client of this.getOrder.list) {
          this.changeOrderList({ account: client.account, add: true, quantity: instrument.quantity });
        }
        console.log(JSON.stringify(this.getOrder));
        await this.sendOrder();
      }
    },
    listUpdate(data) {
      const row = this.instruments.find((each) => each.symbol === data.symbol);
      data.cost = data.price * data.quantity;
      for (const key of Object.keys(data)) row[key] = data[key];
      row.data = data;
    },
    addHot() {
      const block = document.querySelector('.mass-instruments');
      this.height = block.offsetHeight - 65;

      const rows = this.watchList.filter((item) => item.name === 'hot');
      for (const row of rows) {
        const item = {
          key: row.symbol,
          selected: false,
          symbol: row.symbol,
          quantity: 0,
          cost: 0,
          price: row.price,
        };
        item.data = item;
        this.instruments.push(item);
      }
    },
  },
  watch: {
    localMassInstrumentsOrder: {
      handler(newValue, oldValue) {
        this.changeMassInstrumentsOrder(newValue);
        if (newValue) this.addHot();
        if (oldValue) this.instruments = [];
      },
      immediate: false,
    },
  },
  created() {},
  mounted() {},
  unmounted() {},
};
</script>

<style lang="scss" scoped>
.mass-instruments {
  min-height: 100%;

  .header {
    display: flex;
    align-items: flex-end;
    gap: 0 10px;

    :deep(.text) {
      margin-bottom: 0;
    }
  }
}
</style>
