<template>
  <div ref="order-form" class="order-form" @keydown="keyProcessing" @wheel="scrollProcessing">
    <form class="form">
      <div v-if="isMassOrder()" ref="mass-block" class="mass-block">
        <div ref="mass-block-header">
          <c-label text="Список клиентов" />
          <c-select v-model="selectedListName" :options="optionListNames" />
        </div>

        <div ref="mass-block-body" :style="{ maxHeight: tableHeight + 'px' }" class="clients-table-wrapper">
          <c-table :columnDefs="columnDefs" :rows="clientList" @updateRow="listUpdate" />
        </div>

        <div ref="mass-block-footer">
          <c-label text="Описание рекомендации" />
          <textarea v-model="order.deal.general.description" />
        </div>
      </div>
      <div :style="{ justifyContent: isMassInstr ? undefined : 'center' }" class="flex-block">
        <div :class="user.type === 'manager' && modals.orderForm.visible ? 'slim' : ''">
          <div class="ticker-wrap">
            <div>{{ order.deal.orders[1].action === 'buy' ? 'Купить' : 'Продать' }}</div>
            <div>{{ order.deal.general.symbol }}</div>

            <div :class="['icon-float', modals.orderForm.visible ? 'non-floating' : '']" @click="changeMode">
              <img :src="icons.output" alt="" />
            </div>
          </div>
          <div class="form__unit_inline">
            <div data-field="type">
              <c-label :hint-link="currentTypeData.hintLink" :hint-text="currentTypeData.hintText" text="Тип" />
              <c-select v-model="order.deal.orders[1].type" :options="dynamicOrderTypes" />
            </div>
            <div data-field="tif">
              <c-label :hint-link="currentClosingType.hintLink" :hint-text="currentClosingType.hintText" text="Тип закрытия" />
              <c-select v-model="order.deal.orders[1].tif" :options="timePeriods" />
            </div>
          </div>

          <div class="form__unit_inline">
            <div class="item" data-field="quantity">
              <c-label text="Количество" />
              <input
                :disabled="massInstrumentsOrder"
                :value="massInstrumentsOrder ? '' : (order.deal.general.quantity || 0.0).toString().replace('.', ',')"
                inputmode="decimal"
                type="text"
                @input="updateQuantity"
              />
            </div>
            <div class="cost">
              <c-label text="Стоимость" />
              <div class="cost-value" v-text="'~ ' + calcCost(bid || ask)"></div>
            </div>
          </div>

          <div class="form__unit_inline">
            <div class="item" data-field="triggerPrice">
              <c-label text="Стоп цена" />
              <input
                :disabled="!isActive('triggerPrice')"
                :value="priceValue('triggerPrice')"
                inputmode="decimal"
                type="text"
                @input="updatePrice"
              />
            </div>
            <div class="item" data-field="limitPrice">
              <!-- <div :class="[{ danger: danger && !order.deal.orders[1].limitPrice && this.order.deal.orders[1].type === 'limit' }, 'col-item']"> -->
              <c-label text="Цена" />
              <input
                :disabled="!isActive('limitPrice')"
                :value="priceValue('limitPrice')"
                inputmode="decimal"
                type="text"
                @input="updatePrice"
              />
            </div>
          </div>

          <div class="form__unit_inline">
            <div>
              <div class="bid" title="BID">
                <div class="triangle up"></div>
                <div class="title">BID</div>
                <div>
                  <span>{{ printLevelI('bid', 'currency') }}</span>
                  <br />
                  <sup>{{ printLevelI('bidSize', 'size') }}</sup>
                </div>
              </div>
            </div>
            <div>
              <div class="ask" title="ASK">
                <div class="triangle down"></div>
                <div class="title">ASK</div>
                <div>
                  <span>{{ printLevelI('ask', 'currency') }}</span>
                  <br />
                  <sup>{{ printLevelI('askSize', 'size') }}</sup>
                </div>
              </div>
            </div>
          </div>

          <div class="btn-wrap">
            <div
              v-for="action in actions"
              :key="action.value"
              :class="[action.value, { active: order.deal.orders[1].action === action.value }]"
              class="btn"
              @click.stop="order.deal.orders[1].action = action.value"
            >
              {{ action.text }}
            </div>
          </div>

          <div class="order-form-bottom">
            <button v-if="['demo'].includes(user.type)" class="primary center" @click="openAccount">Открыть счет</button>
            <button
              v-else
              :disabled="(order.list.length > 0 && order.list[0].contract_status !== 'Signed') || massInstrumentsOrder"
              class="primary center"
              @click="send"
            >
              Отправить
            </button>
          </div>
        </div>
        <div v-if="isMassInstr" style="flex: 1">
          <c-instruments />
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex';
import { icons } from '@/assets/svg-img';
import animation from '@/utils/animation';
import utils from '@/utils';
import CSelect from '@/components/UI/ComponentSelect.vue';
import CLabel from '@/components/UI/ComponentLabel.vue';
import CTable from '@/components/UI/ComponentTable.vue';
import CInstruments from '@/components/ComponentInstruments.vue';

export default {
  name: 'order-form-component',
  components: {
    CTable,
    CLabel,
    CSelect,
    CInstruments,
  },
  data() {
    return {
      // todo Заменить запросом
      tableHeight: 0,
      optionListNames: [{ value: 'all', text: 'Все клиенты' }],
      selectedListName: '',
      selectedSymbol: null,
      clientList: [],
      columnDefs: [
        { name: '', field: 'selected', type: 'boolean', sort: true, flex: '0 0 35px' },
        { name: 'Клиент', field: 'fio', type: 'string', sort: true, search: 'text', flex: 3 },
        // { name: 'Тип', field: 'type', type: 'string', sort: true, flex: 2 },
        { name: 'Счет', field: 'account', type: 'string', sort: true, search: 'text', flex: 1 },
        // { name: 'Телеграм', field: 'telegram', type: 'string', sort: true, flex: 2 },
        {
          name: 'Количество',
          field: 'quantity',
          type: 'number',
          sort: true,
          editable: (row) => row.selected,
          flex: 1,
        },
        { name: 'Сумма', field: 'cost', type: 'currency', sort: true, flex: 2 },
      ],
      localClients: [],
      icons,
      orderType: null,
      orderTypes: [
        { value: 'market', text: 'Market', available: [] },
        { value: 'limit', text: 'Limit', available: ['limitPrice'] },
        { value: 'stop', text: 'Stop', available: ['limitPrice', 'triggerPrice'] },
        // { value: 'stop_limit', text: 'Stop', available: ['limitPrice', 'triggerPrice'] },
      ],
      orderTypeData: {
        market: {
          hintText:
            'Обычная заявка: очень часто используется у "новичков" на рынках.\n' +
            'Условие выставления читается: купить по любой цене или продать не дешевле цены 0.',
          hintLink: 'https://ptfin.kz/dictionary#market',
        },
        limit: {
          hintText: 'Обычная заявка: частный случай Рыночной заявки. Основная заявка для работы на рынках.',
          hintLink: 'https://ptfin.kz/dictionary#limit',
        },
        stop: {
          hintText:
            'Условная заявка: часто используется для ограничения убытков или для входа в сделку при пробитии ценой какого либо уровня цен.',
          hintLink: 'https://ptfin.kz/dictionary#stop_market',
        },
      },
      orderClosingType: {
        gtc: {
          hintText: 'Выставить приказ со сроком действия до отмены.',
          hintLink: 'https://ptfin.kz/dictionary#gtc',
        },
        day: {
          hintText: 'Выставить приказ со сроком действия до конца текущей торговой сессии.',
          hintLink: 'https://ptfin.kz/dictionary#day',
        },
      },
      bid: 0.0,
      bidSize: 0.0,
      ask: 0.0,
      askSize: 0.0,
      timeoutId: 0,
      touch: { startX: 0.0, startY: 0.0, value: 0.0 },
      actions: [
        { value: 'buy', text: 'Buy' },
        { value: 'sell', text: 'Sell' },
      ],
      timePeriods: [
        { value: 'gtc', text: 'GTC - Good till Canceled' },
        { value: 'day', text: 'DAY' },
      ],
      confirm: {
        order: { modal: false },
        short: { modal: false, isSort: false },
      },
      massManager: [
        '677779fa-299a-36ff-18f3-5ddbc6023dd8', // Сулименко
        // 'e4d8e09a-5fa4-c0a7-efc8-5ddbcdb8df31', // Нем
        // 'dbf806d3-9fb0-a563-9f96-5f5b788d67dc', // Ким
        '66bd2812-9f23-6ef5-082d-6661a3338c20', // Соломатин
        // '18ef58eb-1c51-25b9-0ae3-65afb0511c0b', // Матвеев
        // 'eb6cad41-ac84-fd6c-1fcb-65ae166af412', // Молодецкий
      ],
    };
  },
  computed: {
    utils() {
      return utils;
    },
    ...mapGetters({
      isMobile: 'isMobile',
      user: 'getUser',
      symbols: 'getSymbols',
      levelI: 'getLevelI',
      order: 'getOrder',
      orders: 'getOrders',
      massInstrumentsOrder: 'getMassInstrumentsOrder',
      portfolio: 'getPortfolio',
      modals: 'getModals',
      clientsListNames: 'getClientsListNames',
    }),
    isMassInstr() {
      return this.isMassOrder() && this.user.type === 'manager' && !this.isMobile && this.massManager.includes(this.user.id);
    },
    dynamicOrderTypes() {
      if (this.massInstrumentsOrder) {
        return [{ value: 'market', text: 'Market', available: [] }];
      } else {
        return this.orderTypes;
      }
    },
    currentTypeData() {
      return (
        this.orderTypeData[this.order.deal.orders[1].type] || {
          hintText: 'Unknown order type.',
          hintLink: '#',
        }
      );
    },
    currentClosingType() {
      return (
        this.orderClosingType[this.order.deal.orders[1].tif] || {
          hintText: 'Unknown order type.',
          hintLink: '#',
        }
      );
    },
  },
  methods: {
    ...mapMutations({
      changeOrder: 'changeOrder',
      changeNotifications: 'changeNotifications',
      changeModals: 'changeModals',
      changeOrderList: 'changeOrderList',
    }),
    ...mapActions({
      sendOrder: 'sendOrder',
      pullQuoteSymbol: 'pullQuoteSymbol',
      pullClientsListNames: 'pullClientsListNames',
      pullClientList: 'pullClientList',
    }),
    isMassOrder() {
      return this.user.type === 'manager' && this.modals.orderForm.visible;
      // && this.massManager.includes(this.user.id)
    },
    async setListName() {
      await this.pullClientsListNames();
      if (this.clientsListNames.length > 0) {
        this.optionNames = [];
        for (const list of this.clientsListNames) this.optionNames.push({ value: list.id, text: list.name });
        this.selectedListName = 'all';
      }
    },
    listUpdate(data) {
      this.changeOrderList({ account: data.account, add: data.selected, quantity: data.quantity });
    },
    changeMode() {
      this.changeModals({ orderForm: { visible: !this.modals.orderForm.visible } });
    },
    changeType(type) {
      this.changeOrder({ type });
    },
    isActive(field) {
      return this.orderTypes.find((item) => item.value === this.order.deal.orders[1].type).available.includes(field);
    },
    priceValue(field) {
      return this.order.deal.orders[1][field] === 0 ? '' : this.order.deal.orders[1][field].toString().replace('.', ',');
    },
    calcCost() {
      const symbol = this.symbols[this.order.deal.general.symbol];
      let multiplier = 1.0;
      if (symbol && symbol.asset_category === 'OPT') multiplier = 100.0;
      let price = this.order.deal.orders[1].limitPrice;
      if (price === 0.0) price = (this.order.deal.orders[1].action === 'buy' ? this.ask : this.bid) * multiplier;
      return this.order.deal.general.quantity > 0 && price
        ? utils.getLocateCurrency(this.order.deal.general.quantity * price * multiplier)
        : null;
    },
    send(e) {
      e.preventDefault();
      animation.pressing(e);
      this.sendOrder();
    },
    openAccount(e) {
      e.preventDefault();
      this.changeModals({ boarding: { visible: true, data: {} } });
    },
    onlyFloat(e) {
      e.target.value = e.target.value
        .replace(/[^0-9.,]/g, '')
        .replace(/\./g, ',')
        .replace(/,(?=.*,)/g, '')
        .replace(/^,/, '');
    },
    updateQuantity(e) {
      const symbol = this.symbols[this.order.deal.general.symbol];
      let pattern = /^\d{0,9}$/;
      if (symbol.asset_category === 'OPT') pattern = /^\d{0,9}$/;
      else if (symbol.asset_category === 'CRYPTO') pattern = /^\d{0,9}(,?\d{0,5})?$/;

      pattern.test(e.target.value) || e.target.value === ''
        ? this.changeOrder({ quantity: parseFloat(e.target.value.replace(',', '.') || '0') })
        : (e.target.value = this.order.deal.general.quantity.toString().replace('.', ','));
    },
    updatePrice(e) {
      const symbol = this.symbols[this.order.deal.general.symbol];
      const field = e.target.closest('.item').dataset.field;
      let pattern = /^[0-9]\d{0,9}(,?\d{0,2})?$/;
      if (symbol.asset_category === 'OPT') pattern = /^[0-9]\d{0,9}(,?\d{0,2})?$/;
      else if (symbol.asset_category === 'CRYPTO') pattern = /^[0-9]\d{0,9}(,?\d{0,8})?$/;

      pattern.test(e.target.value) || e.target.value === ''
        ? this.changeOrder({ [field]: parseFloat(e.target.value.replace(',', '.') || '0') })
        : (e.target.value = this.order.deal.orders[1][field] === 0.0 ? '' : this.order.deal.orders[1][field].toString().replace('.', ','));
    },
    getOrderPrice() {
      const symbol = this.order.deal.general.symbol;
      if (symbol && this.levelI[symbol] && this.levelI[symbol].bid && this.levelI[symbol].ask) {
        return this.order.deal.orders[1].action === 'buy' ? this.levelI[symbol].ask : this.levelI[symbol].bid;
      }
      const data = this.symbols[symbol];
      if (data && data.trade) return data.trade.price;
      if (data && data.lp) return data.lp;
      return 0.0;
    },
    printLevelI(name, type) {
      if (this[name] === null) return 'Нет данных';
      if (type === 'currency') return utils.getLocateCurrency(this[name]);
      return this[name];
    },
    updateLevelI(newState) {
      const symbol = this.order.deal.general.symbol;
      if (newState[symbol]) {
        if (this.bid !== newState[symbol].bid) this.bid = newState[symbol].bid;
        if (this.bidSize !== newState[symbol].bidSize) this.bidSize = newState[symbol].bidSize;
        if (this.ask !== newState[symbol].ask) this.ask = newState[symbol].ask;
        if (this.askSize !== newState[symbol].askSize) this.askSize = newState[symbol].askSize;
      }
    },
    updateOrderPrice(orders) {
      if (orders[1].type === 'market') {
        this.changeOrder({ triggerPrice: 0.0, limitPrice: 0.0 });
      } else {
        const price = this.getOrderPrice();
        if (orders[1].type === 'limit') {
          const change = {};
          if (!orders[1].limitPrice) change.limitPrice = price;
          change.triggerPrice = 0.0;
          this.changeOrder(change);
        } else if (orders[1].type === 'stop') {
          if (!orders[1].triggerPrice) {
            this.changeOrder({ triggerPrice: orders[1].limitPrice || price, limitPrice: 0.0 });
          }
        }
        if (price === 0.0) setTimeout(() => this.updateOrderPrice(orders), 2000);
      }
    },
    keyProcessing(e) {
      if (['ArrowUp', 'ArrowDown', 'Tab'].includes(e.key)) {
        e.preventDefault();
        if (e.key === 'Tab') {
          const form = e.target.closest('form');
          let allActiveItem = [];
          let index = -1;
          if (form.querySelector('.table') === null) {
            allActiveItem = Array.from(e.target.closest('form').querySelectorAll('.item:not(:has(input:disabled))'));
            index = allActiveItem.findIndex((item) => item.dataset.field === e.target.closest('.item').dataset.field);
            if (index !== -1) {
              const nextIndex = (index + 1) % allActiveItem.length;
              allActiveItem[nextIndex].querySelector('input').focus();
            }
          } else {
            allActiveItem = Array.from(form.querySelector('.table').querySelectorAll('input.input'));
            index = allActiveItem.findIndex((item) => item === document.activeElement);
            if (index !== -1) {
              const nextIndex = (index + 1) % allActiveItem.length;
              allActiveItem[nextIndex].focus();
            }
          }
        } else {
          const field = e.target.closest('.item');
          if (field.dataset.field !== undefined && !field.classList.contains('sortable')) {
            let step = field.dataset.field === 'quantity' ? 1.0 : 0.01;
            if (e.shiftKey) step = step * 10;
            if (e.key === 'ArrowUp') e.target.value = utils.toLocaleString(utils.toFloat(e.target.value) + step);
            if (e.key === 'ArrowDown') e.target.value = utils.toLocaleString(utils.toFloat(e.target.value) - step);
            e.target.dispatchEvent(new Event('input'));
          }
        }
      }
    },
    scrollProcessing(e) {
      const active = document.activeElement;
      if (active.tagName === 'INPUT') {
        e.preventDefault();
        const field = active.closest('.item');
        if (field.dataset.field !== undefined && !field.classList.contains('sortable')) {
          let step = field.dataset.field === 'quantity' ? 1.0 : 0.01;
          active.value = utils.toLocaleString(utils.toFloat(active.value) + step * Math.round(e.deltaY * 2));
          active.dispatchEvent(new Event('input'));
        }
      }
    },
    handleTouchMove(e) {
      e.preventDefault();
      if (this.touch.startX === 0.0) this.touch.startX = e.touches[0].screenX;
      const changeX = this.touch.startX - e.touches[0].screenX;
      if (changeX > 25) this.changeOrder({ action: 'buy' });
      if (changeX < -25) this.changeOrder({ action: 'sell' });

      if (this.touch.startY === 0.0) this.touch.startY = e.touches[0].screenY;
      const active = document.activeElement;
      if (active.tagName === 'INPUT') {
        if (this.touch.value === 0.0) this.touch.value = active.value;
        let step = active.closest('.item').dataset.field === 'quantity' ? 1.0 : 0.01;
        const changeY = this.touch.startY - e.touches[0].screenY;
        if (changeY > 5.0) active.value = utils.toLocaleString(utils.toFloat(this.touch.value) + step * Math.round(Math.abs(changeY) / 5));
        if (changeY < -5.0) active.value = utils.toLocaleString(utils.toFloat(this.touch.value) - step * Math.round(Math.abs(changeY) / 5));
        active.dispatchEvent(new Event('input'));
      }
    },
    handleTouchEnd() {
      this.touch.startX = 0.0;
      this.touch.startY = 0.0;
      this.touch.value = 0.0;
    },
  },
  watch: {
    massInstrumentsOrder: {
      handler(newValue) {
        if (newValue) this.changeOrder({ type: 'market' });
        else this.changeOrder({ type: 'limit' });
      },
      immediate: true,
    },
    modals: {
      handler(newState) {
        if (newState.orderForm.visible) {
          this.$nextTick(() => {
            const orderForm = this.$refs['order-form'];
            const massBlockBody = this.$refs['mass-block-body'];
            if (orderForm && massBlockBody) {
              const massBlock = this.$refs['mass-block'].offsetHeight;
              const massBlockHeader = this.$refs['mass-block-header'].offsetHeight;
              const massBlockFooter = this.$refs['mass-block-footer'].offsetHeight;
              const margin = getComputedStyle(massBlockBody);

              this.tableHeight =
                massBlock - (massBlockHeader + massBlockFooter + parseInt(margin.marginTop) + parseInt(margin.marginBottom) + 21);
            }
          });
        }
      },
      immediate: true,
      deep: true,
    },
    levelI: {
      handler(newState) {
        this.updateLevelI(newState);
      },
      deep: true,
    },
    orderType(newState) {
      if (newState && this.order.deal.orders[1].type !== newState) {
        this.order.deal.orders[1].type = newState;
        this.updateOrderPrice(this.order.deal.orders);
      }
    },
    order: {
      handler(newState) {
        const changeType = newState.deal.orders[1].type !== this.orderType;
        const changeSymbol = this.selectedSymbol !== newState.deal.general.symbol;

        if (changeType || changeSymbol) {
          this.updateOrderPrice(newState.deal.orders);
          this.orderType = newState.deal.orders[1].type;
          this.selectedSymbol = newState.deal.general.symbol;
        }

        if (this.clientList.length > 0) {
          for (const row of this.clientList) {
            const contract = newState.list.find((each) => each.account === row.account);
            if (contract) {
              row.quantity = contract.quantity;
              row.selected = true;
              if (typeof row.quantity === 'number') {
                const price = newState.deal.orders[1].limitPrice || newState.deal.orders[1].triggerPrice || this.getOrderPrice();
                row.cost = row.quantity * price;
              } else {
                row.cost = null;
              }
            } else {
              row.quantity = null;
              row.selected = false;
              row.cost = null;
            }
          }
        }
      },
      deep: true,
    },
    async selectedListName(newState) {
      const list = await this.pullClientList({ name: newState });
      this.clientList = [];
      for (const data of list) {
        const { id, account, fio } = data;
        this.clientList.push({ key: id, account, fio, selected: false, quantity: null, cost: null, data: data });
      }
    },
  },
  created() {},
  mounted() {
    if (this.order.deal.general.symbol) this.pullQuoteSymbol({ symbol: this.order.deal.general.symbol });
    this.updateOrderPrice(this.order.deal.orders);
    const form = document.querySelector('.order-form');
    form.addEventListener('touchmove', this.handleTouchMove, { passive: false });
    form.addEventListener('touchend', this.handleTouchEnd, { passive: false });
    if (this.isMassOrder()) this.setListName();
  },
  unmounted() {
    const form = document.querySelector('.order-form');
    if (form) {
      form.removeEventListener('touchmove', this.handleTouchMove);
      form.removeEventListener('touchend', this.handleTouchEnd);
    }
  },
};
</script>

<style lang="scss" scoped>
@import '@/style/helper/index';

.clients-table-wrapper {
  min-height: 150px;
  height: 100%;
  margin: 15px 0;
  border-top: 1px rgba(66, 69, 108, 1) solid;

  .table .header .item {
    border-top: 1px rgba(66, 69, 108, 1) solid;
  }
}

.slim {
  margin: auto auto 20px;
  max-width: 600px;
  flex-shrink: 0;
  @include breakpoint-down(md) {
    max-width: 100%;
  }
}

.icon-float {
  width: 15px;
  height: 15px;
  padding: 0 !important;
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 0;
  transition: all 0.3s ease-in-out;

  &.non-floating {
    top: auto;
    bottom: 0;
    transform: rotateZ(-180deg);
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transform: rotateY(-180deg);
  }
}

.order-form {
  background: var(--table-header-bg-color);
  box-shadow: -1px 1px 3px var(--table-header-shadow-color);
  padding: 8px 12px 10px;
  border-radius: var(--main-border-radius);
  // max-width: 730px;
  min-height: 100%;
  height: 100%;

  .form {
    position: relative;
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  textarea {
    width: 100%;
    background-color: var(--input-alt-default-bg);
    border: 1px solid var(--input-alt-default-border-outside);
    color: var(--input-alt-default-text);
    box-shadow: inset 0 1px 0 var(--input-default-shadow-inner-color);
    border-radius: var(--main-border-radius);
    padding: 15px;
    font-size: 11px;
    font-weight: 600;
    resize: vertical;
  }
}

.ticker-wrap {
  display: flex;
  align-items: center;
  justify-content: space-around;
  font-weight: 600;
  margin-bottom: 10px;

  div {
    padding: 0 20px;
  }
}

.order-form-bottom {
  padding-top: 15px;
  border-top: 1px solid var(--input-alt-default-border-outside);

  button {
    width: 130px;
  }
}

.bid,
.ask {
  position: relative;
  padding-left: 16px;
  height: 30px;
  font-weight: 600;
  display: flex;
  align-items: center;

  .title {
    margin-right: 8px;
    font-size: 12px;
  }

  .triangle {
    width: 0;
    height: 0;
    margin-right: 2px;

    &.up {
      @extend .triangle;
      border-left: 6px solid transparent;
      border-right: 6px solid transparent;
      border-top: 10px solid var(--profit-green);
    }

    &.down {
      @extend .triangle;
      border-left: 6px solid transparent;
      border-right: 6px solid transparent;
      border-bottom: 10px solid var(--loss-red);
    }
  }

  sup {
    color: var(--white);
    font-size: 10px;
  }

  span {
    font-size: 12px;
  }
}

.bid {
  color: var(--profit-green);
}

.ask {
  color: var(--loss-red);
}

.btn-wrap {
  display: flex;
  justify-content: space-between;
  position: relative;
  margin: 12px 0 14px;

  input {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    opacity: 0;
    height: 100%;
  }
}

.cost {
  font-size: 12px;
  font-weight: 600;
  border-bottom: 1px solid var(--input-alt-default-border-outside);

  &-value {
    transform: translate(0, 3px);
  }
}

.btn {
  border-radius: var(--main-border-radius);
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--buy-button-default-text-color);
  height: 24px;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
  @include breakpoint-down(md) {
    height: 35px;
  }
}

.primary {
  @include breakpoint-down(md) {
    height: 35px;
    font-weight: bold;
  }
}

.buy {
  text-align: center;
  flex-grow: 0.5;
  background: rgba(0, 128, 0, 0);
  border: solid 1px rgba(0, 255, 0, 0.6);
  margin-right: 5px;

  &.active {
    background: rgba(0, 255, 0, 0.6);
  }
}

.danger {
  border: 1px solid var(--alert);
}

.sell {
  text-align: center;
  flex-grow: 0.5;
  background: rgba(255, 0, 0, 0);
  border: solid 1px rgba(255, 0, 0, 0.7);
  margin-left: 5px;

  &.active {
    background: rgba(255, 0, 0, 0.7);
  }
}

.mass-block {
  flex-grow: 1;
}

.ticker-wrap {
  flex-shrink: 0;
}

.flex-block {
  display: flex;

  .slim {
    margin: 0 30px 0;

    @include breakpoint-down(md) {
      margin: 0 auto;
    }
  }
}
</style>
