<template>
  <div class="photo-uploader">
    <div
      :class="{ 'photo-uploader__wrapper--drag': isDragStarted, 'photo-uploader__wrapper--no-photos': !hasPhotos }"
      class="photo-uploader__wrapper"
    >
      <input
        :accept="accept"
        :disabled="disabled"
        class="photo-uploader__input"
        directory
        multiple
        title=""
        type="file"
        @change="uploadFile($event.target)"
        @dragenter="isDragStarted = true"
        @dragleave="isDragStarted = false"
      />
      {{ isDragStarted ? '' : uploadText }}
      <img v-show="isDragStarted" alt="Загрузите фото" class="photo-uploader__icon" />
    </div>
    <div class="photo-uploader__photos">
      <div
        v-for="(file, index) in localValue.filter((each) => each.documentType === documentType)"
        :key="index"
        class="photo-uploader__photo"
      >
        <button v-if="!disabled" class="photo-uploader__remove" @click.prevent="removeFile(file)"></button>
        <embed :alt="`Фотография ${index + 1}`" :src="getSrc(file)" :type="file.type" />
      </div>
    </div>
  </div>
</template>

<script>
import { icons } from '@/assets/svg-img';

export default {
  name: 'UploaderComponent',
  emits: ['update:modelValue', 'remove'],
  components: {},
  props: {
    modelValue: {
      type: Array,
      required: true,
    },
    documentType: {
      type: String,
      required: true,
    },
    accept: {
      type: String,
    },
    disabled: { type: Boolean, default: false },
  },
  data() {
    return {
      icons,
      input: null,
      isDragStarted: false,
    };
  },
  computed: {
    localValue: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit('update:modelValue', value);
      },
    },
    uploadText() {
      if (this.needToUpload > 0)
        return 'Загрузите или перетащите не более ' + this.needToUpload + (this.needToUpload === 1 ? ' файлa' : ' файлов');
      if (this.needToUpload === 0) return 'Вы загрузили все фотографии';
      return 'Вы загрузили слишком много фотографий';
    },
    needToUpload() {
      return 3 - this.localValue.filter((each) => each.documentType === this.documentType).length || 0;
    },
    hasPhotos() {
      return this.localValue.filter((each) => each.documentType === this.documentType).length > 0;
    },
  },
  methods: {
    uploadFile(target) {
      if (target.files) {
        for (const file of target.files) {
          file.status = 'local';
          file.documentType = this.documentType;
        }
        this.$emit('update:modelValue', [...this.localValue, ...Array.from(target.files)]);
      }
      target.value = '';
      this.isDragStarted = false;
    },
    removeFile(file) {
      this.$emit('remove', file);
    },
    getSrc(photo) {
      if (photo.status === 'active') {
        return process.env.VUE_APP_DOCUMENT_URL + photo.url;
      }
      return URL.createObjectURL(photo);
    },
  },
  created() {},
  mounted() {},
  beforeUnmount() {},
};
</script>

<style lang="scss" scoped>
@import '@/style/helper/index';

.photo-uploader {
  position: relative;
  display: flex;
  align-items: flex-start;
  gap: 0 5px;
  @include breakpoint-down(md) {
    display: block;
  }

  &__wrapper {
    position: relative;
    text-align: center;
    height: 90px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    border: 1px solid var(--input-alt-default-border-outside);
    box-shadow: inset 0 1px 0 var(--input-default-shadow-inner-color);
    border-radius: var(--main-border-radius);
    color: var(--table-column-text-header-color);
    width: 50%;
    flex-shrink: 0;
    @include breakpoint-down(md) {
      width: 100%;
      margin-bottom: 10px;
      height: 60px;

      &--no-photos {
        height: 120px;
      }
    }

    &--drag {
      color: rgba(#fff, 0);
      border-color: #777;
    }
  }

  &__input {
    cursor: pointer;
    position: absolute;
    z-index: 2;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
  }

  &__photos {
    display: flex;
    width: calc(50% - 10px);
    flex-shrink: 0;
    gap: 0 5px;
    @include breakpoint-down(md) {
      width: 100%;
    }
  }

  &__photo {
    position: relative;
    width: calc(100% / 3);
    @include breakpoint-down(md) {
      height: 100px;
    }

    img {
      border-radius: 5px;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    embed {
      border-radius: 5px;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__remove {
    cursor: pointer;
    position: absolute;
    outline: none;
    top: -3px;
    right: 0;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background-color: rgb(0 0 0 / 60%);
    border: 0;
    padding: 0;

    &::before {
      content: '';
      position: absolute;
      z-index: 1;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 8px;
      height: 2px;
      background-color: #fff;
    }
  }

  &__icon {
    opacity: 0.3;
    width: 50px;
  }
}
</style>
