<template>
  <div class="option-chart-wrap">
    <div class="title">
      {{ title }}
      <div v-if="showIcon" class="icon" @click.stop="close">
        <img :src="icons.cross" alt="" />
      </div>
      <div v-else class="icon" title="увеличить график" @click="zoom($event)">
        <img :src="icons.zoom" alt="" />
      </div>
    </div>
    <div v-show="tooltipVisible" class="tooltip">
      <div v-if="info.strike">Strike: {{ info.strike }}</div>
      <div class="wrap">
        <div v-for="each in info.lines" :key="each.name" :style="'color:' + each.color">{{ each.name }}: {{ each.value }}</div>
      </div>
    </div>
    <canvas ref="chart" @mouseleave="handleMouseLeave" @mousemove="handleMouseMove" />
  </div>
</template>

<script>
import utils from '@/utils';
import { icons } from '@/assets/svg-img';

function initialInfo() {
  return JSON.parse(JSON.stringify({ strike: '', lines: [] }));
}

export default {
  name: 'ComponentChart',
  emits: ['zoom', 'close'],
  data() {
    return {
      icons,
      showIcon: false,
      ctx: null,
      axisX: {},
      axisY: {},
      points: [],
      border: 12,
      colors: ['red', 'rgba(119, 119, 252, 0.8)', 'green'],
      tooltipContent: '',
      tooltipVisible: false,
      info: initialInfo(),
    };
  },
  props: {
    title: { type: String, default: () => '' },
    name: { type: String, default: () => '' },
    lines: { type: Object, required: true },
  },
  methods: {
    close(e) {
      this.$emit('close', e);
      this.showIcon = false;
      this.resizeCanvas();
    },
    zoom(e) {
      this.showIcon = true;
      this.$emit('zoom', e);
      this.resizeCanvas();
    },
    handleMouseLeave() {
      this.tooltipVisible = false;
    },
    handleMouseMove(e) {
      const rect = this.$refs.chart.getBoundingClientRect();
      const x = e.clientX - rect.left;
      const dataX = this.nearestAxisX(x);
      this.$refs.chart.style.cursor = 'crosshair';
      if (dataX) {
        this.tooltipVisible = true;
        this.tooltipContent = this.formatTooltipContent(dataX);
      }
    },
    formatTooltipContent(data) {
      if (Object.keys(data).length > 0) {
        this.info.strike = data[this.axisX.name];
        for (const index in this.lines.lines) {
          const infoLine = this.info.lines.find((each) => each.name === this.lines.lines[index].name);
          const pointY = this.lines.lines[index].data.find((each) => each[this.axisX.name] === data[this.axisX.name]);
          if (infoLine && pointY) infoLine.value = pointY[this.axisY.name];
        }
      }
    },
    nearestAxisX(x) {
      let minDistance = Infinity;
      let result = {};
      if (this.points.length === 0 && this.lines.lines.length !== 0) this.points = this.getPoints(this.lines.lines[0].data);
      for (const point of this.points) {
        const distance = Math.sqrt((x - point.x) ** 2);
        if (distance < minDistance) {
          result = point.data;
          minDistance = distance;
        }
      }
      return result;
    },
    preparation() {
      this.ctx.clearRect(0, 0, this.ctx.canvas.width, this.ctx.canvas.height);
      this.ctx.lineWidth = 1;
      this.ctx.beginPath();
      this.ctx.stroke();
    },
    curve(line, color) {
      const points = this.getPoints(line.data);
      this.info.lines.push({ name: line.name, color, value: '' });
      if (points.length === 0) return;

      points.sort((a, b) => a.x - b.x);

      let progress = 0;
      const totalPoints = points.length;

      const drawAnimatedLine = () => {
        this.ctx.strokeStyle = color;
        this.ctx.lineWidth = 1;
        this.ctx.beginPath();

        const visiblePointsCount = Math.floor(totalPoints * progress);
        const visiblePoints = points.slice(0, visiblePointsCount);

        if (visiblePoints.length > 0) {
          this.ctx.moveTo(visiblePoints[0].x, visiblePoints[0].y);
          for (let i = 1; i < visiblePoints.length; i++) {
            this.ctx.lineTo(visiblePoints[i].x, visiblePoints[i].y);
          }
        }

        this.ctx.stroke();

        progress += 0.035;
        if (progress < 1) {
          requestAnimationFrame(drawAnimatedLine);
        } else {
          this.ctx.beginPath();
          this.ctx.moveTo(points[0].x, points[0].y);
          for (let i = 1; i < points.length; i++) {
            this.ctx.lineTo(points[i].x, points[i].y);
          }
          this.ctx.stroke();
        }
      };

      drawAnimatedLine();
    },
    getPoints(line) {
      const rangeX = utils.findMinMax(line, this.axisX.name, this.axisX.type);
      const rangeY = utils.findMinMax(line, this.axisY.name, this.axisY.type);
      const stepX = (this.ctx.canvas.width - this.border * 2) / (rangeX.max - rangeX.min);
      const stepY = (this.ctx.canvas.height - this.border * 2) / (rangeY.max - rangeY.min);

      const result = [];
      for (let i = 0; i < line.length; i++) {
        if (line[i][this.axisY.name] !== null) {
          result.push({
            x: this.border + (utils.typing(line[i][this.axisX.name], this.axisX.type) - rangeX.min) * stepX,
            y: this.ctx.canvas.height - this.border - (utils.typing(line[i][this.axisY.name], this.axisY.type) - rangeY.min) * stepY,
            data: line[i],
          });
        }
      }
      return result;
    },
    chart() {
      this.preparation();
      this.info = initialInfo();
      this.axisX = this.lines.axisX;
      this.axisY = this.lines.axisY;
      for (const index in this.lines.lines) {
        this.curve(this.lines.lines[index], this.colors[index]);
      }
    },
    resizeCanvas() {
      const canvas = this.$refs.chart;
      const parent = canvas.parentElement;
      const dpr = window.devicePixelRatio || 1;

      const width = parent.clientWidth;
      const height = parent.clientHeight - 28;

      canvas.width = width * dpr;
      canvas.height = height * dpr;
      canvas.style.width = `${width}px`;
      canvas.style.height = `${height}px`;

      this.chart();
    },
  },
  watch: {
    lines() {
      this.chart();
    },
  },
  mounted() {
    this.ctx = this.$refs.chart.getContext('2d');
    this.resizeCanvas();
    window.addEventListener('resize', this.resizeCanvas);
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.resizeCanvas);
  },
};
</script>

<style lang="scss" scoped>
.option-chart-wrap {
  position: relative;
  height: 100%;
  width: 100%;
  border: 1px solid var(--input-alt-default-border-outside);
  border-radius: var(--main-border-radius);
  box-shadow: 0 2px 10px 0 var(--overlay-box-shadow-color);

  .title {
    text-transform: capitalize;
    background: var(--table-header-bg-color);
    box-shadow: -1px 1px 3px var(--table-header-shadow-color);
    color: var(--table-column-text-header-color);
    padding: 5px 10px;
    height: 28px;
    display: flex;
    justify-content: space-between;
  }

  canvas {
    display: block;
    height: calc(100% - 28px);
    width: 100%;
  }

  .tooltip {
    background-color: rgba(0, 0, 0, 0.25);
    box-shadow: -1px 1px 3px rgba(0, 0, 0, 0.25);
    padding: 5px;
    color: #fff;
    text-transform: capitalize;
    pointer-events: none;
    position: absolute;
    right: 0;
    top: 28px;
    font-weight: 600;
    font-size: 12px;
    backdrop-filter: blur(3px);

    .wrap {
      display: flex;
      gap: 4px;
      font-size: 10px;
    }
  }
}

.icon {
  width: 15px;
  height: 15px;
  cursor: pointer;

  img {
    width: 100%;
    height: 100%;
  }
}
</style>
