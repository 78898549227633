<template>
  <div v-for="option in options" :key="option.value" class="custom-radio">
    <label>
      <div class="title">{{ option.title }}</div>
      <input v-model="localValue" :disabled="disabled" :name="name" :value="option.value" type="radio" />
      <div class="indicator">
        <img :src="icons.check" alt="" />
      </div>
    </label>
  </div>
</template>

<script>
import { icons } from '@/assets/svg-img';

export default {
  name: 'CustomRadio',
  emits: ['update:modelValue'],
  components: {},
  props: {
    name: { type: String, default: () => '' },
    options: { type: Array, default: () => [] },
    modelValue: {
      type: String,
      // required: true,
      default: () => '',
    },
    disabled: { type: Boolean, default: false },
  },
  data() {
    return { icons };
  },
  computed: {
    localValue: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit('update:modelValue', value);
      },
    },
  },
  methods: {},
  watch: {},
  created() {},
  mounted() {},
  unmounted() {},
};
</script>

<style lang="scss" scoped>
@import '@/style/helper/index';

.custom-radio {
  label {
    display: inline-flex !important;
    align-items: center;
    cursor: pointer;
    position: relative;
    color: var(--pale-violet);

    .title {
      font-size: 12px;
      @include breakpoint-down(md) {
        font-size: 14px;
      }
    }

    input {
      position: absolute;
      inset: 0;
      opacity: 0;

      &:checked + .indicator {
        img {
          opacity: 1;
        }
      }
    }

    &:hover {
      .indicator {
        border: 1px solid var(--input-hovered-border-outside-color);
      }
    }
  }

  .indicator {
    width: 16px;
    height: 16px;
    background-color: rgb(130 136 201 / 37%);
    border: solid 1px var(--table-header-shadow-color);
    margin-left: 4px;
    padding: 1px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: border 0.2s ease-in-out;
    flex-shrink: 0;
    @include breakpoint-down(md) {
      width: 18px;
      height: 18px;
    }
  }

  img {
    opacity: 0;
    transition: all 0.2s ease;
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}
</style>
