<template>
  <!--  Открытие счета-->
  <c-modal :visible="modals.boarding.visible" mobile-screen size="small" title="Открытие счета" @close="modals.boarding.visible = false">
    <account-open @close="modals.boarding.visible = false" />
  </c-modal>

  <!--  Приветствие-->
  <c-modal :visible="modals.about.visible" size="small" title="Приветствие" @close="modals.about.visible = false">
    <about />
  </c-modal>

  <!--  Экономический профиль-->
  <c-modal :visible="modals.profile.visible" size="small" title="Экономический профиль" @close="modals.profile.visible = false">
    <profile @close="modals.profile.visible = false" />
  </c-modal>

  <!--  Опционы-->
  <c-modal :visible="modals.options.visible" size="large" title="Опционы" @close="modals.options.visible = false">
    <options-component />
  </c-modal>

  <!--  Выставление приказа-->
  <c-modal
    :size="modalSize"
    :visible="modals.orderForm.visible"
    no-padding
    title="Выставление приказа"
    @close="changeModals({ orderForm: { visible: false, data: {} } })"
  >
    <order-form-component />
  </c-modal>

  <!--  todo переделать модалки подтверждения шорт и лонг -->
  <!--  Выставление приказа лонг - шорт -->
  <conformation-order
    :data="modals.orderConfirm.data"
    :visible="modals.orderConfirm.visible"
    @close="modals.orderConfirm.visible = false"
    @confirm="modalConfirm('order')"
    @refuse="modals.orderConfirm.visible = false"
  />

  <!--  todo переделать модалки подтверждения шорт и лонг -->
  <!--  Выставление приказа лонг - шорт -->
  <conformation-short
    :data="modals.shortConfirm.data"
    :visible="modals.shortConfirm.visible"
    @close="modals.shortConfirm.visible = false"
    @confirm="modalConfirm('short')"
    @refuse="modals.shortConfirm.visible = false"
  />

  <!--  автоследование -->
  <c-modal
    :visible="modals.autoFollow.visible"
    size="small"
    title="Подтверждение автоследования"
    @close="modals.autoFollow.visible = false"
  >
    <auto-follow />
  </c-modal>

  <!--  настройки индикатора-->
  <c-modal
    :blackout="false"
    :title="'настройки индикатора ' + modals.indicatorSettings.data.name"
    :visible="modals.indicatorSettings.visible"
    fixed-right
    size="small"
    @close="modals.indicatorSettings.visible = false"
  >
    <indicator-setting :data="modals.indicatorSettings.data" />
  </c-modal>

  <!--  настройки WatcList-->
  <c-modal :visible="modals.wlSetting.visible" size="small" title="настройки WatcList" @close="modals.wlSetting.visible = false">
    <wl-setting :data="modals.wlSetting.data" />
  </c-modal>

  <!--  Добавить инструмент-->
  <c-modal :visible="modals.addInstrument.visible" title="Добавить инструмент" @close="modals.addInstrument.visible = false">
    <search-list />
  </c-modal>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex';
import CModal from '@/components/UI/ComponentModal.vue';
import AccountOpen from '@/components/Instructions/Boarding.vue';
import About from '@/components/Instructions/About.vue';
import Profile from '@/components/Instructions/Profile.vue';
import OptionsComponent from '@/components/OptionsComponent.vue';
import OrderFormComponent from '@/components/OrderFormComponent.vue';
import ConformationOrder from '@/components/conformation/ConformationOrder.vue';
import ConformationShort from '@/components/conformation/ConformationShort.vue';
import AutoFollow from '@/components/Instructions/AutoApproval.vue';
import IndicatorSetting from '@/components/chart/IndicatorSetting.vue';
import WlSetting from '@/components/WatchlistSetting.vue';
import SearchList from '@/components/SearchList.vue';

export default {
  name: 'ListModals',
  components: {
    SearchList,
    WlSetting,
    IndicatorSetting,
    AutoFollow,
    ConformationShort,
    ConformationOrder,
    OrderFormComponent,
    OptionsComponent,
    Profile,
    AccountOpen,
    About,
    CModal,
  },
  data: function () {
    return {};
  },
  computed: {
    ...mapGetters({
      user: 'getUser',
      order: 'getOrder',
      modals: 'getModals',
    }),
    modalSize() {
      return this.user.type === 'manager' ? 'medium' : 'small';
    },
  },
  methods: {
    ...mapMutations({
      changeModals: 'changeModals',
    }),
    ...mapActions({
      sendOrder: 'sendOrder',
    }),
    async modalConfirm(name) {
      this.order.confirmation[name] = true;
      this.modals[name + 'Confirm'].visible = false;
      await this.sendOrder();
      // if (this.isMobile) this.$router.push({ name: 'orders' });
    },
  },
  watch: {},
  created() {},
  mounted() {},
  unmounted() {},
};
</script>

<style lang="scss" scoped></style>
