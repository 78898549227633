<template>
  <div class="indicator-setting">
    <div class="body">
      <div v-for="(item, index) in localData.calcParams" :key="index" class="indicator">
        <div class="title">Параметр {{ index + 1 }}</div>
        <div class="val">
          <input v-model.number="localData.calcParams[index]" type="text" />
        </div>
      </div>
    </div>

    <div class="footer">
      <!--      <button class="warning">Сбросить</button>-->
      <button class="primary" @click="close">Применить</button>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';

export default {
  name: 'IndicatorSetting',
  data: function () {
    return {
      localData:
        this.data && Array.isArray(this.data.calcParams)
          ? {
              ...this.data,
              calcParams: [...this.data.calcParams],
            }
          : { calcParams: [] },
    };
  },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    ...mapGetters({
      chartMainIndicators: 'getChartMainIndicators',
      modals: 'getModals',
    }),
  },
  methods: {
    ...mapMutations({
      changeIndicatorSettings: 'changeIndicatorSettings',
    }),
    ...mapActions({}),
    close() {
      this.modals.indicatorSettings.visible = false;
    },
    apply() {
      this.changeIndicatorSettings(this.localData);
    },
  },
  watch: {},
  created() {
    this.apply();
  },
  mounted() {},
  unmounted() {},
};
</script>

<style lang="scss" scoped>
.indicator-setting {
  display: flex;
  flex-direction: column;
  height: 100%;

  .body {
    padding-bottom: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .indicator {
    display: flex;
    align-items: flex-end;
    margin-bottom: 10px;

    &:last-child {
      margin-bottom: 0;
    }

    .title {
      width: 90px;
    }

    .val {
      width: 60px;

      input {
        width: 100%;
      }
    }
  }

  .footer {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 20px;
    display: flex;
    justify-content: center;
    gap: 10px;
  }
}
</style>
